<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <el-button type="primary" plain size="mini" v-if="userBusinessManagementAddFlag" @click="userBusinessManagementAdd">新增</el-button>
                        <el-button type="primary" plain size="mini" v-if="userBusinessManagementModifyFlag" @click="userBusinessManagementModify">修改</el-button>
                        <el-button type="primary" plain size="mini" v-if="userBusinessManagementDelFlag" @click="userBusinessManagementDel">删除</el-button>
                        <el-button type="primary" plain size="mini" v-if="userBusinessManagementFreezeFlag" @click="userBusinessManagementFreeze">冻结</el-button>
                        <!--<el-button type="primary" plain size="mini" v-if="userBusinessManagementRegionalFlag" @click="userBusinessManagementRegional">区域管理</el-button>-->
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入业务员姓名（模糊匹配）" v-model="Salesman_name" size="mini" style="width: 250px"  class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="高级查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="账号名称" prop="username">
                                                    <el-input v-model="dataForms.username" placeholder="支持模糊匹配"></el-input>
                                                </el-form-item>
                                                <el-form-item label="用户手机号" prop="phone">
                                                    <el-input  v-model="dataForms.phone"></el-input>
                                                </el-form-item>
                                                <el-form-item label="用户状态" prop="status">
                                                    <el-select v-model="dataForms.status" placeholder="请选择用户状态" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="启用" value="1"></el-option>
                                                        <el-option label="冻结" value="0"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="用户所属公司ID" prop="companyId">
                                                    <el-input  v-model="dataForms.companyId"></el-input>
                                                </el-form-item>
                                                <el-form-item label="用户所属公司名称" prop="companyName">
                                                    <el-input  v-model="dataForms.companyName" placeholder="支持模糊匹配"></el-input>
                                                </el-form-item>
                                                <el-form-item label="用户所属角色ID" prop="roleId">
                                                    <el-input  v-model="dataForms.roleId"></el-input>
                                                </el-form-item>
                                                <el-form-item label="用户所属角色名称" prop="roleName">
                                                    <el-input  v-model="dataForms.roleName" placeholder="支持模糊匹配"></el-input>
                                                </el-form-item>
                                                <el-form-item label="枚举名称" prop="roleEnum">
                                                    <el-select v-model="dataForms.roleEnum" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="admin" value="admin"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="是否管理正泰业务" prop="chintBusiness">
                                                    <el-select v-model="dataForms.chintBusiness" placeholder="请选择" style="width: 100%">
                                                        <el-option
                                                                v-for="item in chintBusiness_options"
                                                                :key="item.value"
                                                                :label="item.label"
                                                                :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="是否管理外阜" prop="outGoing">
                                                    <el-select v-model="dataForms.outGoing" placeholder="请选择" style="width: 100%">
                                                        <el-option
                                                                v-for="item in outGoing_options"
                                                                :key="item.value"
                                                                :label="item.label"
                                                                :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="区域级别" prop="regionLevel">
                                                    <el-select v-model="dataForms.regionLevel" placeholder="请选择" style="width: 100%">
                                                        <el-option
                                                                v-for="item in regionLevel_options"
                                                                :key="item.value"
                                                                :label="item.label"
                                                                :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                                                <el-button size="mini" v-if="userBusinessManagementSearchFlag" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>

                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    @handleClosButtonClick="handleClosButtonClick"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <el-dialog
                :title="dialogTitleUserManagement"
                :visible.sync="dialog_userManagement"
                width="85%"
                :modal="true"
                center
                :before-close="handleClose_userManagement">
            <el-card class="box-card" :body-style="{ padding: '10px' }">
                <div slot="header">
                    <span style="font-size: 14px">业务员信息</span>
                </div>
                <div>
                    <el-form :model="userManagementForm"  ref="userManagementForm" :rules="userManagementFormrules" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="账户名称" prop="username">
                                    <el-input v-model="userManagementForm.username" :disabled="userManageModifyFlag[0]"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" v-if="userManageModifyFlag[1]">
                                <el-form-item label="密码" prop="password">
                                    <el-input v-model="userManagementForm.password"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="用户真实姓名" prop="name">
                                    <el-input v-model="userManagementForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="性别" prop="gender">
                                    <el-select v-model="userManagementForm.gender">
                                        <el-option label="男" value="1"></el-option>
                                        <el-option label="女" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="用户手机号" prop="phone">
                                    <el-input v-model="userManagementForm.phone" :disabled="userManageModifyFlag[2]"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="固话" prop="telephone">
                                    <el-input v-model="userManagementForm.telephone"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="邮箱" prop="email">
                                    <el-input v-model="userManagementForm.email"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="用户状态" prop="status">
                                    <el-select v-model="userManagementForm.status">
                                        <el-option label="启用" value="1"></el-option>
                                        <el-option label="冻结" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="所属系统" prop="userType">
                                    <el-select v-model="userManagementForm.userType">
                                        <el-option label="城市配" value="1"></el-option>
                                        <el-option label="系统管理" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="所属公司" prop="companyName">
                                    <el-input v-model="userManagementForm.companyName" @focus="companyFocus"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="角色" prop="roleName">
                                    <el-input v-model="userManagementForm.roleName" @focus="roleFocus"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="save_userManagement">提 交</el-button>
                <el-button @click="handleClose_userManagement">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog title="公司查询" :visible.sync="dialogTableVisible">
            <el-row>
                <el-form :inline="true"  v-model="form" size="mini" label-position="center">
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="编码" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="form.companyId" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="名称" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="form.companyName" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <el-button type="primary" plain size="mini" @click="companySearch">查询</el-button>
                            <el-button type="primary" plain size="mini" @click="companyOkay">确定</el-button>
                        </div>
                    </el-col>
                </el-form>
            </el-row>
            <el-row class="waybill_add_dialog">
                <flight-table
                        :tableData="tableDatadialog"
                        :columns="columns"
                        :pagesizes="pagesizes"
                        :isPagination='true'
                        @handleSelectionChange="handleSelectionChange"
                        @clickTable="clickTable"
                        @handleCurrentChange="handleCurrentChange_compan"
                        @handleSizeChange = 'handleSizeChange'
                        :total="tableDatadialog_pagination.total"
                        :pageSize="tableDatadialog_pagination.pageSize"
                        :current-page="tableDatadialog_pagination.pageNum"
                ></flight-table>
            </el-row>
        </el-dialog>
        <el-dialog title="角色查询" :visible.sync="dialogTableVisible_role">
            <el-row>
                <el-form :inline="true"  v-model="form_role" size="mini" label-position="center">
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="编码" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="form_role.roleId" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="名称" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="form_role.roleName" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <el-button type="primary" plain size="mini" @click="companySearch_role">查询</el-button>
                            <el-button type="primary" plain size="mini" @click="companyOkay_role">确定</el-button>
                        </div>
                    </el-col>
                </el-form>
            </el-row>
            <el-row class="waybill_add_dialog">
                <flight-table
                        :tableData="tableDatadialog_role"
                        :columns="columns_role"
                        :pagesizes="pagesizes_role"
                        :isPagination='true'
                        @handleSelectionChange="handleSelectionChange_role"
                        @clickTable="clickTable_role"
                        @handleCurrentChange="handleCurrentChange_role"
                        @handleSizeChange = 'handleSizeChange_role'
                        :total="tableDatadialog_pagination_role.total"
                        :pageSize="tableDatadialog_pagination_role.pageSize"
                        :current-page="tableDatadialog_pagination_role.pageNum"
                ></flight-table>
            </el-row>
        </el-dialog>
        <div>
            <el-dialog
                    title="分配区域管理权限"
                    :visible.sync="dialogVisibleMenu"
                    :close-on-click-modal="false"
                    :before-close="tree_handleClose"
                    v-if="addDialogVisible"
                    width="30%">


                <el-tree
                        :data="menuTreeList"
                        show-checkbox
                        ref="tree"
                        node-key="adcode"
                        empty-text="加载中，请稍后"
                        :props="defaultProps"
                        :default-checked-keys="checkedMenuIds"
                        check-strictly
                        @check="checkChange"
                ></el-tree>
                <div style="margin-top: 20px" align="center">
                    <el-button type="primary" @click="handleSaveMenu()">保存</el-button>
                    <!--<el-button @click="handleClearMenu()">清空</el-button>-->
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import Drawer from '../../components/common/drawer.vue'
    import axios from 'axios'
    import qs from 'qs'
    import arrayQuery from '../../components/arrayQuery.js'
    import 'element-ui/lib/theme-chalk/index.css'
    import flightTable from "../../components/common/flightTable.vue"
    export default {
        data(){
            return {
                checkedMenuIds:[],
                table: false,
                dialog: false,
                loading: false,
                Salesman_name:'',
                userid:'',
                dialogTitleUserManagement:'',
                dialog_userManagement:false,
                dialogTableVisible: false,
                dialogTableVisible_role: false,
                userManageModifyFlag:[false,true,false],
                tableDatadialog: [],
                tableDatadialog_role: [],
                companyData:[],
                roleData:[],
                chintBusiness_options: [],
                outGoing_options:[],
                regionLevel_options:[],
                dataForms:{
                    id:'',
                    username:'',
                    name:'',
                    phone:'',
                    status:'',
                    companyId:'',
                    companyName:'',
                    roleId:'',
                    roleName:'',
                    roleEnum:'',
                    chintBusiness:'',
                    outGoing:'',
                    regionLevel:'0'
                },
                userManagementForm:{
                    id:'',
                    username: '',
                    password:'',
                    name:'',
                    gender:'1',
                    phone:'',
                    telephone: '',
                    email:'',
                    status:'1',
                    userType:'1',
                    companyId:'',
                    companyName:'',
                    roleId:'',
                    roleName:''
                },
                userManagementFormrules:{
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur' },
                        { min: 7, max: 12, message: "请填写7-12位数字字母组合", trigger: "change" },
                        {
                            pattern: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,12}$',
                            message: "用户名必须数字+字母组合",
                            trigger: "blur"
                        }
                    ],
                    password: [
                        { required: true,message: '请输入密码', trigger: 'blur' },
                        { min: 8, max: 12, message: "密码需要8-12位字母、数字、特殊字符", trigger: "change" },
                        {
                            pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^(0-9a-zA-Z)]).{8,12}$',
                            message: "密码设置需要数字+字母+特殊符合",
                            trigger: "blur"
                        }
                    ],
                    name: [
                        { required: true, message: '请输入您的真实姓名', trigger: 'blur' }
                    ],
                    roleName: [
                        { required: true, message: '角色必填', trigger: ['blur', 'change'] }
                    ],
                    companyName: [
                        { required: true, message: '公司必填', trigger: ['blur', 'change'] }
                    ],
                    email: [
                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                    ],
                    phone:[
                        { required: true, message: "请输入手机号码", trigger: "blur" },
                        { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
                        {
                            pattern: '^1[345789]\\d{9}$',
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ]
                },
                form:{
                    companyId:'',
                    companyName:''
                },
                form_role:{
                    roleId:'',
                    roleName:''
                },
                columns: [
                    {
                        id: "selection",
                        type: "selection",
                        label: "",
                        fixed: "left",
                        width: "55",
                        prop: "",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "companyId",
                        type: "",
                        label: "编码",
                        fixed: false,
                        width: "120",
                        prop: "companyId",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "companyName",
                        type: "",
                        label: "名称",
                        fixed: false,
                        width: "180",
                        prop: "companyName",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "companyType",
                        type: "",
                        label: "使用类型",
                        fixed: false,
                        width: "130",
                        prop: "companyType",
                        isShow: true,
                        align: "center",
                        formatter: (row) => {
                            if (row.companyType == '1') {
                                return "承运商/司机";
                            } else if (row.companyType == '0') {
                                return "普通用户";
                            } else {
                                return "";
                            }
                        }
                    },
                    {
                        id: "moduleId",
                        type: "",
                        label: "所属模块",
                        fixed: false,
                        width: "130",
                        prop: "moduleId",
                        isShow: true,
                        align: "center",
                        formatter: (row) => {
                            if (row.moduleId == '1') {
                                return "城市配";
                            } else if (row.moduleId == '0') {
                                return "系统管理";
                            } else {
                                return "";
                            }
                        }
                    }
                ],
                columns_role: [
                    {
                        id: "selection",
                        type: "selection",
                        label: "",
                        fixed: "left",
                        width: "55",
                        prop: "",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "roleId",
                        type: "",
                        label: "编码",
                        fixed: false,
                        width: "80",
                        prop: "roleId",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "roleName",
                        type: "",
                        label: "角色名称",
                        fixed: false,
                        width: "240",
                        prop: "roleName",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "roleEnum",
                        type: "",
                        label: "枚举名称",
                        fixed: false,
                        width: "110",
                        prop: "roleEnum",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "moduleId",
                        type: "",
                        label: "所属模块",
                        fixed: false,
                        width: "130",
                        prop: "moduleId",
                        isShow: true,
                        align: "center",
                        formatter: (row) => {
                            if (row.moduleId == '1') {
                                return "城市配";
                            } else if (row.moduleId == '0') {
                                return "系统管理";
                            } else {
                                return "";
                            }
                        }
                    }
                ],
                tableDatadialog_pagination:{
                    pageSize:10,
                    pageNum:1,
                    total:0
                },
                tableDatadialog_pagination_role:{
                    pageSize:10,
                    pageNum:1,
                    total:0
                },
                pagesizes: [5, 10, 15, 20],
                pagesizes_role: [5, 10, 15, 20],
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'id',prop:'id',width:'120',align:'center'},
                    {label:'账户名称',prop:'username',align:'center'},
                    {label:'用户姓名',prop:'name',align:'center'},
                    {label:'性别',prop:'gender',align:'center',
                        formatter: (row) => {
                            if (row.gender == 1) {
                                return "男";
                            } else if (row.gender == 0) {
                                return "女";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'手机号',prop:'phone',align:'center'},
                    {label:'用户座机',prop:'telephone',align:'center'},
                    {label:'用户邮箱',prop:'email',align:'center'},
                    {label:'启用状态',prop:'status',align:'center',
                        formatter: (row) => {
                            if (row.status == 1) {
                                return "启用";
                            } else if (row.status == 0) {
                                return "冻结";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'用户类型',prop:'userType',align:'center',
                        formatter: (row) => {
                            if (row.userType == 1) {
                                return "城市配";
                            } else if (row.userType == 0) {
                                return "系统管理";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'用户所属公司ID',prop:'companyId',align:'center',width:'120'},
                    {label:'用户所属公司名称',prop:'companyName',align:'center',width:'120'},
                    {label:'用户所属角色ID',prop:'roleId',align:'center',width:'120'},
                    {label:'用户所属角色名称',prop:'roleName',align:'center',width:'120'},
                    {label:'角色枚举名称',prop:'roleEnum',align:'center',width:'120'},
                    {label:'是否正泰管理业务',prop:'chintBusiness',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.chintBusiness == 1) {
                                return "不管正泰业务";
                            } else if (row.chintBusiness == 0) {
                                return "管正泰业务";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'是否外阜',prop:'outGoing',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.outGoing == 1) {
                                return "管市内";
                            } else if (row.outGoing == 0) {
                                return "管外阜";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'区域级别',prop:'regionLevel',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.regionLevel == 0) {
                                return "所有";
                            } else if (row.regionLevel == 1) {
                                return "province";
                            } else if (row.regionLevel == 2) {
                                return "city";
                            } else if (row.regionLevel == 3) {
                                return "region";
                            } else {
                                return "全国";
                            }
                        }
                    },
                    {label:'创建人',prop:'creator',align:'center',width:'120'},
                    {label:'修改人',prop:'modifier',align:'center'},
                    {label:'创建时间',prop:'createTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'修改时间',prop:'modifyTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.modifyTime == null){
                                return ''
                            }else {
                                return this.$moment(row.modifyTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {
                        id: "button",
                        type: "Button",
                        label: "操作",
                        fixed: "right",
                        width: "120",
                        prop: "",
                        align: "center",
                        btnList: [
                            {
                                size:'mini',
                                type:'primary',
                                id: "stopSingle",
                                label: "区域管理",
                            }
                        ]
                    },
                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[],
                dialogVisibleMenu:false,
                menuTreeList:[],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                roleId:null,
                userBusinessManagementSearchFlag:true,
                userBusinessManagementAddFlag:true,
                userBusinessManagementModifyFlag:true,
                userBusinessManagementDelFlag:true,
                userBusinessManagementFreezeFlag:true,
                userBusinessManagementRegionalFlag:true,
                expandTreeNodesByDefault:[],
                addDialogVisible:false
            }
        },

        components:{ //引入组件后注册组件
            cesTable,
            Drawer,
            flightTable
        },
        mounted(){
            this.getTableAndForm();
            this.getMenuTree();
            let parentId = '2_3_7';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let  userBusinessManagementSearch = currentPageButtons.find(item => {
                return item.menuId == '2_3_7_1';
            });
            if( userBusinessManagementSearch == undefined){
                _this.userBusinessManagementSearchFlag = false;
            }else {
                _this.userBusinessManagementSearchFlag = true;
            }
            //新增按钮权限
            let userBusinessManagementAdd = currentPageButtons.find(item => {
                return item.menuId == '2_3_7_2';
            });
            if(userBusinessManagementAdd == undefined){
                _this.userBusinessManagementAddFlag = false;
            }else {
                _this.userBusinessManagementAddFlag = true;
            }
            //修改按钮权限
            let userBusinessManagementModify = currentPageButtons.find(item => {
                return item.menuId == '2_3_7_3';
            });
            if(userBusinessManagementModify == undefined){
                _this.userBusinessManagementModifyFlag = false;
            }else {
                _this.userBusinessManagementModifyFlag = true;
            }
            //删除按钮权限
            let userBusinessManagementDel = currentPageButtons.find(item => {
                return item.menuId == '2_3_7_4';
            });
            if(userBusinessManagementDel == undefined){
                _this.userBusinessManagementDelFlag = false;
            }else {
                _this.userBusinessManagementDelFlag = true;
            }
            //冻结按钮权限
            let userBusinessManagementFreeze = currentPageButtons.find(item => {
                return item.menuId == '2_3_7_5';
            });
            if(userBusinessManagementFreeze == undefined){
                _this.userBusinessManagementFreezeFlag = false;
            }else {
                _this.userBusinessManagementFreezeFlag = true;
            }
            //区域管理按钮权限
            let userBusinessManagementRegional = currentPageButtons.find(item => {
                return item.menuId == '2_3_7_6';
            });
            if(userBusinessManagementRegional == undefined){
                _this.userBusinessManagementRegionalFlag = false;
            }else {
                _this.userBusinessManagementRegionalFlag = true;
            }
            let userPermissions = JSON.parse(sessionStorage.getItem('userPermissions'));
            _this.userid = userPermissions.id;
            if(userPermissions.chintBusiness == null){
                //表示权限全部都有
                _this.chintBusiness_options = [
                    {
                        value: '',
                        label: '全部'
                    },
                    {
                        value: '0',
                        label: '管正泰业务'
                    }, {
                        value: '1',
                        label: '不管正泰业务'
                    }
                ]
            }else if(userPermissions.chintBusiness == 0){
                _this.chintBusiness_options = [
                    {
                        value: '0',
                        label: '管正泰业务'
                    }
                ]
            }else if(userPermissions.chintBusiness == 1){
                _this.chintBusiness_options = [
                    {
                        value: '1',
                        label: '不管正泰业务'
                    }
                ]
            }
            //用户是否管理外阜
            if(userPermissions.outGoing == null){
                //表示权限全部都有
                _this.outGoing_options = [
                    {
                        value: '',
                        label: '全部'
                    },{
                        value: '0',
                        label: '管外阜'
                    }, {
                        value: '1',
                        label: '管市内'
                    }
                ]
            }else if(userPermissions.outGoing == 0){
                _this.outGoing_options = [
                    {
                        value: '0',
                        label: '管外阜'
                    }
                ]
            }else if(userPermissions.outGoing == 1){
                _this.outGoing_options = [
                    {
                        value: '1',
                        label: '管市内'
                    }
                ]
            }
            //用户可查询的区域级别
            if(userPermissions.regionLevel == null){
                //表示权限全部都有
                _this.regionLevel_options = [
                    {
                        value: '0',
                        label: '所有'
                    },{
                        value: '',
                        label: '全国'
                    },{
                        value: '1',
                        label: '省'
                    }, {
                        value: '2',
                        label: '市'
                    },{
                        value: '3',
                        label: '区/县'
                    }
                ]
            }else if(userPermissions.regionLevel == 1){
                _this.regionLevel_options = [
                    {
                        value: '2',
                        label: '市'
                    },{
                        value: '3',
                        label: '区/县'
                    }
                ]
            }else if(userPermissions.regionLevel == 2){
                _this.regionLevel_options = [
                    {
                        value: '3',
                        label: '区/县'
                    }
                ]
            }
        },
        methods:{
            //点击树节点
            checkChange(a) {
                const self = this
                const anode = this.$refs.tree.getNode(a)
                if (anode.checked) {//删除选中的
                    this.setParentChecked(anode.parent)
                    //点击权限子节点
                    anode.childNodes.map(res=>{
                        res.checked=true
                    })
                } else {//新增选中的
                    this.deleteParentChecked(anode.parent)
                    this.deleteChildChecked(anode.childNodes)
                }
            },
            setParentChecked(parent) {//如果不是全选中为父级添加半选状态，如果子集全选后，父级也要全选
                const fnode = this.$refs.tree.getNode(parent)
                const isAllChecked = fnode.childNodes.every(k => k.checked && k.indeterminate === false)//子集是否是全选
                if (!fnode.isLeaf) {
                    fnode.indeterminate = isAllChecked//子集是否是全选，如果子集全选，则半选状态为假
                    fnode.checked = true
                }
                if (fnode.parent) {
                    this.setParentChecked(fnode.parent)
                }
            },
            deleteParentChecked(parent, d = false) {//如果取消子节点的选中， 设置父级节点选中状态
                const fnode = this.$refs.tree.getNode(parent)
                const isAllChecked = fnode.childNodes.some(k => d ? (k.checked || k.indeterminate) : k.checked)//子集是否是全选
                if (!fnode.isLeaf) {
                    fnode.indeterminate = !isAllChecked//子集是否是全选，如果子集全选，则半选状态为假
                    fnode.checked = isAllChecked
                    if (fnode.parent) {//如果有父节点，则需要去判断父节点是否选中
                        this.deleteParentChecked(fnode.parent, true)
                    }
                }
            },
            deleteChildChecked(childNodes) {//删除子节点的勾选状态
                if (childNodes && childNodes.length > 0) {
                    childNodes.map(k => {
                        k.indeterminate = false
                        k.checked = false
                        this.deleteChildChecked(this.$refs.tree.getNode(k).childNodes)
                    })
                }
            },
            // checkbox的触发接收
            handleSelectionChange(val) {
                this.companyData = val.val;
            },
            // checkbox的触发接收
            handleSelectionChange_role(val) {
                this.roleData = val.val;
            },
            // 双击事件的行触发
            clickTable(val) {
                this.userManagementForm.companyId = val.row.companyId;
                this.userManagementForm.companyName = val.row.companyName;
                this.dialogTableVisible = false;
            },
            // 双击事件的行触发
            clickTable_role(val) {
                this.userManagementForm.roleId = val.row.roleId;
                this.userManagementForm.roleName = val.row.roleName;
                this.dialogTableVisible_role = false;
            },
            //公司
            companyFocus(){
                this.companySearch();
                this.dialogTableVisible = true;
            },
            //角色
            roleFocus(){
                this.companySearch_role();
                this.dialogTableVisible_role = true;
            },
            handleSizeChange(val){
                this.tableDatadialog_pagination.pageSize = val;
                this.companySearch();
            },
            handleSizeChange_role(val){
                this.tableDatadialog_pagination_role.pageSize = val;
                this.companySearch_role();
            },
            handleCurrentChange_compan(val) {
                this.tableDatadialog_pagination.pageNum = val;
                this.companySearch();
            },
            handleCurrentChange_role(val) {
                this.tableDatadialog_pagination_role.pageNum = val;
                this.companySearch_role();
            },
            companySearch(){
                const data = new URLSearchParams();
                data.append('companyId', this.form.companyId);
                data.append('companyName', this.form.companyName);
                data.append('pageSize', this.tableDatadialog_pagination.pageSize);
                data.append('pageNo', this.tableDatadialog_pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/company/queryUrabnBusinessCompanyCheckPage`,
                    data:data
                }).then(res=> {
                    this.tableDatadialog = res.data.items;
                    this.tableDatadialog_pagination.total = res.data.total;
                });
            },
            companySearch_role(){
                const data = new URLSearchParams();
                data.append('roleId', this.form_role.roleId);
                data.append('roleName', this.form_role.roleName);
                data.append('pageSize', this.tableDatadialog_pagination_role.pageSize);
                data.append('pageNo', this.tableDatadialog_pagination_role.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/role/queryUrbanBusinessRoleCheckPage`,
                    data:data
                }).then(res=> {
                    this.tableDatadialog_role = res.data.items;
                    this.tableDatadialog_pagination_role.total = res.data.total;
                });
            },
            companyOkay(){
                if(this.companyData.length == 0){
                    this.$message({
                        message: '请先勾选一条公司信息',
                        type: 'warning'
                    });
                }else if(this.companyData.length > 1){
                    this.$message({
                        message: '新增用户仅允许勾选一条公司信息',
                        type: 'warning'
                    });
                }else {
                    this.userManagementForm.companyId = this.companyData[0].companyId;
                    this.userManagementForm.companyName = this.companyData[0].companyName;
                    this.dialogTableVisible = false;
                }
            },
            companyOkay_role(){
                if(this.roleData.length == 0){
                    this.$message({
                        message: '请先勾选一条角色信息',
                        type: 'warning'
                    });
                }else if(this.roleData.length > 1){
                    this.$message({
                        message: '新增用户仅允许勾选一条角色信息',
                        type: 'warning'
                    });
                }else {
                    this.userManagementForm.companyId = this.roleData[0].companyId;
                    this.userManagementForm.companyName = this.roleData[0].companyName;
                    this.dialogTableVisible_role = false;
                }
            },
            //新增
            userBusinessManagementAdd(){
                this.dialog_userManagement = true;
                this.userManageModifyFlag = [false,true,false];
                this.dialogTitleUserManagement = '新增业务员';
            },
            //修改
            userBusinessManagementModify(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '修改操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '修改操作只能选择单条数据进行操作'
                    });
                }else{
                    this.userManageModifyFlag = [true,false,true];
                    this.dialogTitleUserManagement = '修改业务员';
                    axios.get(`${this.$apiPath}/api/pm/user/findUserInfosById/`+ this.merge[0].id).then(res=>{
                        if(res.data.data.status == true){
                            res.data.data.status = '1'
                        }else if(res.data.data.status == false){
                            res.data.data.status = '0'
                        }
                        if(res.data.data.gender == true){
                            res.data.data.gender = '1'
                        }else if(res.data.data.gender == false){
                            res.data.data.gender = '0'
                        }
                        res.data.data.userType = JSON.stringify(res.data.data.userType)
                        this.userManagementForm = res.data.data;
                        this.dialog_userManagement = true;
                    })
                }
            },
            //删除
            userBusinessManagementDel(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '删除操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '删除操作只能选择单条用户数据进行操作'
                    });
                }else{
                    this.$confirm('此操作将对用户进行删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        axios.get(`${this.$apiPath}/api/pm/user/deleteUserInfosById/`+ this.merge[0].id).then(res=>{

                            if(res.data.success == true){
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.userBusinessManagementSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }
            },
            //冻结
            userBusinessManagementFreeze(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '冻结操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '冻结操作只能选择单条用户数据进行操作'
                    });
                }else{
                    this.$confirm('此操作将对用户进行冻结, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        axios.get(`${this.$apiPath}/api/pm/user/updateUserStatus/`+ this.merge[0].id).then(res=>{
                            if(res.data.success == true){
                                this.$message({
                                    message: '冻结成功',
                                    type: 'success'
                                });
                                this.userBusinessManagementSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }
            },
            //区域管理
            userBusinessManagementRegional(){

            },
            tree_handleClose(){
                this.expandTreeNodesByDefault.length = 0;
                this.dialogVisibleMenu = false;
                this.addDialogVisible = false;
            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.userBusinessManagementSearch();
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.userBusinessManagementSearch();
            },
            //快捷查询  查询业务员姓名
            handleQuery(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.loading = true;
                const data = new URLSearchParams();
                data.append('name', this.Salesman_name);
                data.append('regionLevel', this.dataForms.regionLevel);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/user/queryUrbanBusinessUserInfos`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //查询
            userBusinessManagementSearch(){
                this.loading = true;
                const data = new URLSearchParams();
                data.append('id', this.dataForms.id);
                data.append('username', this.dataForms.username);
                data.append('name', this.dataForms.name);
                data.append('phone', this.dataForms.phone);
                data.append('status', this.dataForms.status);
                data.append('companyId', this.dataForms.companyId);
                data.append('companyName', this.dataForms.companyName);
                data.append('roleId', this.dataForms.roleId);
                data.append('roleName', this.dataForms.roleName);
                data.append('roleEnum', this.dataForms.roleEnum);
                data.append('chintBusiness', this.dataForms.chintBusiness);
                data.append('outGoing', this.dataForms.outGoing);
                data.append('regionLevel', this.dataForms.regionLevel);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/user/queryUrbanBusinessUserInfos`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            userBusinessManagementSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.userBusinessManagementSearch();
            },
            handleClose() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.userBusinessManagementSearch_Multiple()
                    // 动画关闭需要一定的时间
                    this.dialog = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                clearTimeout(this.timer);
            },
            handleClosButtonClick(val) {
                this.dialogVisibleMenu=true;
                this.addDialogVisible = true;
                this.roleId=val.index.id;
                this.queryMenuByRoleId();
            },
            //查询所有菜单树
            getMenuTree(){
                axios.get(`${this.$apiPath}/api/pm/region/queryUserRegionsAllTree`).then(res=>{
                    this.menuTreeList= res.data;
                })
            },
            //查询要回显的菜单树
            queryMenuByRoleId(){
                axios.get(`${this.$apiPath}/api/pm/region/queryUserRegions/`+ this.roleId).then(res=>{
                    const roleMenu=res.data;
                    const checkedMenuIds=[];
                    for(let i=0;i<roleMenu.length;i++){
                        if(roleMenu!=null && roleMenu.length>0){
                            checkedMenuIds.push(roleMenu[i].adcode);
                        }
                    }
                    this.checkedMenuIds = checkedMenuIds;
                    this.$refs.tree.setCheckedKeys(checkedMenuIds);
                })
            },
            //新增分配菜单
            handleSaveMenu() {
                const checkedMenu = this.$refs.tree.getCheckedNodes();
                const checkedMenuIds = [];
                for (let i = 0; i < checkedMenu.length; i++) {
                    let aa = {};
                    aa.adcode = checkedMenu[i].adcode;
                    aa.name = checkedMenu[i].name;
                    aa.level = checkedMenu[i].level;
                    aa.adcodeParent = checkedMenu[i].adcodeParent;
                    checkedMenuIds.push(aa);
                }
                this.$confirm('是否要分配该地域权限?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params = {};
                    params.userId = this.roleId;
                    params.userRegionVos  = checkedMenuIds;
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/pm/region/updateUserRegions`,
                        data:params
                    }).then(res=> {
                        if(res.data.success == true){
                            this.$message({
                                message: '操作成功',
                                type: 'success'
                            });
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                        this.dialogVisibleMenu = false;
                        this.$refs.tree.setCheckedKeys([])
                    });
                })

            },
            handleClearMenu(){

            },
            //保存数据
            save_userManagement(){
                this.$refs["userManagementForm"].validate(valid => {
                    if (valid) {
                        const data = new URLSearchParams();
                        data.append('id', this.userManagementForm.id);
                        data.append('username', this.userManagementForm.username);
                        data.append('password', this.userManagementForm.password);
                        data.append('name', this.userManagementForm.name);
                        data.append('gender', this.userManagementForm.gender);
                        data.append('phone', this.userManagementForm.phone);
                        data.append('telephone', this.userManagementForm.telephone);
                        data.append('email', this.userManagementForm.email);
                        data.append('status',this.userManagementForm.status);
                        data.append('companyId', this.userManagementForm.companyId);
                        data.append('roleId',this.userManagementForm.roleId);
                        data.append('userType',this.userManagementForm.userType);
                        if(this.dialogTitleUserManagement == '新增业务员'){
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/pm/user/addUserInfos`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '新增成功',
                                        type: 'success'
                                    });
                                    this.handleClose_userManagement();
                                    this.userBusinessManagementSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.handleClose_userManagement();
                                }
                            });
                        }else if(this.dialogTitleUserManagement == '修改业务员'){
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/pm/user/updateUserInfos`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '修改成功',
                                        type: 'success'
                                    });
                                    this.handleClose_userManagement();
                                    this.userBusinessManagementSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.handleClose_userManagement();
                                }
                            });
                        }
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });

            },
            handleClose_userManagement(){
                this.dialog_userManagement = false;
                this.$refs.userManagementForm.resetFields();
            },
        }
    }
</script>
<style>
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
    .waybill_add_dialog .ces-table{
        height: 200px;
    }
    .waybill_add_dialog .el-table th{
        padding: 0;
        height: 30px;
    }
    .waybill_add_dialog .el-table td{
        padding: 0;
        height: 30px;
    }
</style>
<style scoped>

    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>